$green: #35A835;
$darkgreen: #007832;
$dark: #2A2A2D;
$grey: #37373a;
$light-grey: #6c6c6c;
$light: #CCC;
$background: #F7F7F7;
$red: #b61336;

$font-size: 1rem;

* {
	box-sizing: border-box;
	font-family: 'Barlow Semi Condensed', sans-serif;
	font-weight: 400;
}

html, body {
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
}

body {
	background-color: $background;
	display: flex;

	#immo-app {
		display: flex;
		flex: 1;
		font-size: $font-size;
		.main-wrapper{
			display: flex;
			flex: 1;
		}
		.button-group{
			display: flex;
			justify-content: flex-end;
		}
		.form-wrapper{
			display: flex;
			flex: 1;
			height: 100%;
			justify-content: center;
			align-items: center;
			background-color: $light;
			.form{
				background-color: white;
				padding: 2rem;
				display: flex;
				flex-direction: column;
				box-shadow: 0 14px 28px rgba(0,0,0,0.12), 0 10px 10px rgba(0,0,0,0.1);
				border-top: 1rem solid $green;
				h1{
				}
				label{
					width: 100%;
					min-width: 20rem;
					margin: 0 0 1rem;
					input{
						width: 100%;
						background-color: $background;
					}
				}
				button{
					background-color: $grey;
					color: white;
					flex: 1;
					margin: 1rem 0 0;
					padding: 0.5rem 0;
					justify-content: center;
					&:hover{
						background-color: $green;
					}
					+ button {
						margin-left: 1rem;
					}
					&.light{
						background-color: $light;
					}
				}
			}
		}
		.red{
			color: red;
		}
		.green{
			color: green;
		}
		.remove{
			color: black;
			position: absolute;
			right: 0.5rem;
			top: 0.5rem;
			width: 3rem;
			height: 3rem;
			background: white;
			padding: 0.5rem;
			font-size: 1.4rem;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			cursor: pointer;
			transition: all 0.35s;
			&:hover{
				background-color: red;
				color: white;
			}
		}
		.alert{
			position: fixed;
			top: 0;
			left: 0;
			z-index: 2000;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: rgba(0,0,0,0.75);
			.inner{
				background-color: white;
				display: flex;
				flex-direction: column;
				width: 30rem;
				div{
					padding: 2rem;
					&:last-child{
						display: flex;
						justify-content: flex-end;
						button{
							background-color: $light-grey;
							padding: 0.5rem 2rem;
							margin-left: 1rem;
							color: white;
							&:hover{
								border-color: transparent;
								background-color: $grey;
							}
						}
					}
				}
			}
		}
		button{
			background: none;
			font-size: $font-size;
			display: flex;
			align-items: center;
			cursor: pointer;
			border: 1px solid transparent;
			transition: all 0.35s;
			&:not(.disabled):hover, &:not(.disabled):focus{
				outline: none;
				border: 1px solid white;
			}
			svg{
				margin-left: 0.5rem;
			}
			&.disabled{
				opacity: 0.25;
				cursor: default;
			}
		}
		label{
			display: flex;
			flex: 1;
			flex-direction: column;
			span{
				margin: 0 0 0.5rem;
			}
			input, select, textarea{
				font-size: $font-size;
				padding: 0 1rem;
				line-height: 3rem;
				height: 3rem;
				margin-right: 2rem;
				border-radius: 0;
				border: none;
				&:focus{
					border-radius: 0;
					border-color: green;
					outline: none;
				}
			}
			input{
				&:read-only{
					background-color: #DDD;
					color: #999;
				}
			}
			select{
				cursor: pointer;
				-webkit-appearance:none;
				-moz-appearance:none;
				appearance:none;
			}
			&.select{
				position: relative;
				svg{
					position: absolute;
					right: 3rem;
					height: 100%;
					pointer-events: none;
				}
			}
			textarea{
				padding: 1rem;
				height: 10rem;
				line-height: 1.4rem;
			}
			&.checkbox{
				flex: 0;
				flex-direction: row;
				align-items: center;
				input{
					flex: 0;
				}
			}
		}
		.checkbox{
			margin-right: 2rem;
			cursor: pointer;
			input{
				margin: 0 1rem 0 0;
				&:checked{
					+ .check{
						border-color: green;
						svg{
							display: flex;
						}
					}
				}
			}
			.check{
				display: flex;
				align-items: center;
				justify-content: center;
				width: 1.4rem;
				height: 1.4rem;
				border: 2px solid $light;
				position: absolute;
				z-index: 10;
				background: white;
				font-size: 3rem;
				color: $green;
				svg{
					display: none;
				}
			}
		}
		.modal{
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			background-color: rgba(0,0,0,0.5);
			padding: 8rem;
			flex-direction: column;
			z-index: 100;
		}
		.main {
			display: flex;
			flex: 1;

			.content {
				display: flex;
				flex: 1;
				flex-direction: column;
				height: 100%;
				.header{
					display: flex;
					justify-content: space-between;
					padding: 4rem;
					button{
						background: none;
						border: none;
						font-size: 3rem;
					}
					.add{
						height: 4rem;
						width: 4rem;
						display: flex;
						align-items: center;
						justify-content: center;
						border: none;
						border-radius: 5rem;
						font-size: 3rem;
						background-color: white;
						cursor: pointer;
						transition: all 0.35s;
						&:hover{
							background-color: $green;
							color: white;
						}
					}
					.search {
						display: flex;
						justify-content: center;
						flex: 1;

						.input {
							position: relative;
							flex: 0 1 40%;
							display: flex;
							align-items: center;

							input {
								width: 100%;
								padding: 0 3rem;
								height: 4rem;
								border: none;
								border-radius: 5rem;
								font-size: 2rem;

								&:focus {
									outline: none;
									box-shadow: none;
									border: none;
								}
							}

							button {
								position: absolute;
								right: 2rem;
								border: none;
								background: none;
								font-size: 3rem;
							}
						}
					}
				}
				.properties{
					display: flex;
					flex: 1;
					.properties-inner{
						display: flex;
						flex-wrap: wrap;
						padding: 2rem;
						.property{
							background-color: white;
							display: flex;
							flex-basis: calc(20% - 4rem);
							margin: 2rem;
							flex-direction: column;
							cursor: pointer;
							transition: all 0.35s;
							position: relative;
							&:hover{
								box-shadow: 0 0.5rem 2rem -1rem rgba(0,0,0,0.25);
							}
							.image-wrapper{
								width: 100%;
								height: 14rem;
								background-size: cover;
								background-position: center;
								background-color: #DDD;
							}
							.inner{
								padding: 2rem;
								h3{
									margin-top: 0;
								}
							}
							.active{
								position: absolute;
								top: 1rem;
								left: 1rem;
								width: 4rem;
								background: white;
								height: 2rem;
								border-radius: 1rem;
								span{
									position: absolute;
									top: 0.25rem;
									height: 1.5rem;
									width: 1.5rem;
									border-radius: 50%;
								}
								&.true{
									span{
										right: 0.25rem;
										background-color: $green;
									}
								}
								&.false{
									span{
										left: 0.25rem;
										background-color: $light-grey;
									}
								}
							}
							.infos{
								border-top: 1px solid #CCC;
								padding: 0.5rem 0 0;
								margin: 2rem 0 0;
								p{
									display: flex;
									justify-content: space-between;
									margin: 0.25rem 0 0;
								}
							}
						}
					}
				}
				.property{
					.preview-wrapper{
						position: absolute;
						top: 0;
						left: 0;
						height: 100%;
						width: 100%;
						z-index: 1000;
						display: flex;
						align-items: center;
						justify-content: center;
						background-color: rgba(0,0,0,0.75);
						.preview{
							width: 60%;
							height: 60%;
							background-repeat: no-repeat;
							background-size: contain;
							background-position: center;
						}
						.close{
							position: absolute;
							top: 3rem;
							right: 3rem;
							color: $dark;
							background-color: white;
							border-radius: 3rem;
							width: 4rem;
							height: 4rem;
							display: flex;
							align-items: center;
							justify-content: center;
							font-size: 2rem;
							cursor: pointer;
						}
						.prev, .next{
							color: white;
							font-size: 6rem;
							position: absolute;
							left: 10%;
							top: 50%;
							transform: translateY(-50%);
							cursor: pointer;
							&.disabled{
								opacity: 0.25;
							}
						}
						.next{
							right: 10%;
							left: auto;
						}
					}
					.items{
						.left {
							display: flex;
							flex: 2;
							.items-inner{
								.item{
									flex-basis: 50%;
									position: relative;
									.unit{
										position: absolute;
										right: 2rem;
										bottom: 0.5rem;
										height: 3rem;
										background-color: $light-grey;
										color: white;
										padding: 0 1rem;
										display: flex;
										align-items: center;
										justify-content: center;
									}
								}
								label{
									span{
										color: $green;
										font-size: 1.2rem;
									}
								}
							}
						}
						.right{
							flex-direction: column;
							display: flex;
							flex: 1;
							margin: 0 2rem;
							position: relative;
							.loader{
								background-color: rgba(247, 247, 247, 0.75);
							}
							.fileupload{
								background: red;
								position: relative;
								max-height: 4rem;
								.upload{
									background-color: white;
									border: 2px dashed #AAA;
									position: absolute;
									left: 0;
									top: 0;
									width: 100%;
									height: 100%;
									display: flex;
									align-items: center;
									justify-content: center;
									cursor: pointer;
									transition: all 0.35s;
									&:hover{
										box-shadow: 0 0.5rem 2rem -1rem rgba(0,0,0,0.25);
									}
									span{
										font-size: 2rem;
										color: #666;
									}
								}
							}
							.thumbs{
								display: flex;
								flex: 1;
								position: relative;
								.thumb-wrapper{
									overflow: hidden;
									.thumbs-inner{
										display: flex;
										flex: 1;
										height: 100%;
										flex-wrap: wrap;
										align-items: flex-start;
										margin: 0 -0.5rem;
										padding: 1rem 0 0;

										.thumb{
											display: flex;
											flex: 0 0 calc(33% - 1rem);
											height: 8rem;
											margin: 0 0.5rem 1rem 0.5rem;
											background-size: cover;
											background-position: center;
											position: relative;
											cursor: pointer;
											opacity: 0.75;
											transition: all 0.35s;
											&:hover{
												opacity: 1;
												background-size: 110%;
												box-shadow: 0 0.25rem 0.5rem -0.25rem rgba(0,0,0,0.75);
												.remove{
													display: flex;
													opacity: 0.8;
												}
											}
											.remove{
												transition: all 0.35s;
												display: none;
												opacity: 0;
											}
											&.dragging{
												opacity: 0.25;
											}
											&.line{
												position: relative;
												&.up{
													&:before{
														content: "";
														display: block;
														position: absolute;
														left: 0;
														top:0;
														width: 3rem;
														height: 100%;
														border-left: 2px dashed $dark;
														background-color: $background;
													}
												}
												&.down{
													&:after{
														content: "";
														display: block;
														position: absolute;
														right: 0;
														top:0;
														width: 3rem;
														height: 100%;
														border-right: 2px dashed $dark;
														background-color: $background;
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
				.property,
				.config {
					.head {
						display: flex;
						align-items: center;
						padding: 1rem 4rem;
						background-color: green;
						justify-content: space-between;
						.left, .right{
							display: flex;
						}
						.right{
							button{
								margin: 0 0 0 2rem;
							}
						}
						button{
							margin: 0 2rem 0 0;
							color: white;
							background-color: rgba(255,255,255,0.25);
							padding: 0.5rem 1rem;
						}
					}

					.items {
						display: flex;
						flex: 1;
						height: 100%;
						padding: 2rem;
						background-color: $background;
						position: relative;
						flex-wrap: wrap;
						overflow: hidden;
						#drag-items{
							position: absolute;
							top: 100%;
							width: calc(80%);
							background-color: transparent;
							opacity: 0.9;
							display: flex;
							.item-wrapper{
								display: flex;
								.checkbox, button{
									display: none;
								}
							}
						}

						.items-inner{
							display: flex;
							flex: 1;
							flex-wrap: wrap;
							padding: 0 2rem;
							.item {
								flex: 1 1 100%;
								padding-bottom: 1rem;
								&.line{
									&.up{
										&:before{
											content: "";
											display: block;
											width: 100%;
											height: 3rem;
											background: #DDD;
											margin-bottom: 1rem;
											border: 2px dashed #CCC;
										}
									}
									&.down{
										&:after{
											content: "";
											display: block;
											width: 100%;
											height: 3rem;
											background: #DDD;
											margin-top: 1rem;
											border: 2px dashed #CCC;
										}
									}
								}
								&.dragging{
									opacity: 0.5;
								}
								label{
									flex: 0;
									&:not(.checkbox):not(.unit){
										min-width: 14rem;
									}
									&.unit{
										min-width: 10rem;
									}
									&.full{
										flex: 1;
									}
								}
								.item-wrapper{
									flex: 1;
									display: flex;
									align-items: flex-end;
								}
								.rearrange{
									width: 2rem;
									height: 3rem;
									display: flex;
									align-items: center;
									font-size: 2rem;
									color: $light-grey;
									cursor: pointer;
								}
								.controls{
									display: flex;
									align-items: center;
									width: 10rem;
									justify-content: flex-end;
									label{
										span{
											margin: 0;
										}
									}
								}

							}
							button{
								background-color: $light-grey;
								color: white;
								height: 3rem;
								width: 3rem;
								display: flex;
								justify-content: center;
								font-size: 1.4rem;
								svg{
									margin: 0;
								}
								&:not(.disabled):hover{
									background-color: $red;
								}
							}
						}
					}
				}
			}
		}
	}
}
.toast{
	position: fixed;
	top: 2rem;
	width: 20rem;
	left: 50%;
	transform: translateX(-50%);
	z-index: 2000;
	background-color: white;
	box-shadow: 0 1rem 2rem -2rem #000;
	animation: fadeToast 4s forwards;
	.title{
		color: white;
		&.success{
			background-color: $green;
		}
		&.error{
			background-color: $red;
		}
	}
	p{
		padding: 1rem;
		margin: 0;
	}
}

@keyframes fadeToast{
	0%{
		opacity: 0;
	}
	10%{
		opacity: 1;
	}
	90%{
		margin-top: 0;
		opacity: 1;
	}
	100%{
		margin-top: -4rem;
		opacity: 0;
	}
}
@import "loading";
